@use "@angular/material" as mat;

@include mat.ripple();
@include mat.core();
@include mat.all-component-typographies(mat.m2-define-typography-config());
@include mat.button-theme($vts-light-theme);
@include mat.icon-button-theme($vts-light-theme);
@include mat.checkbox-theme($vts-light-theme);
@include mat.core-theme($vts-light-theme);
@include mat.divider-theme($vts-light-theme);
@include mat.icon-theme($vts-light-theme);
@include mat.list-theme($vts-light-theme);
@include mat.menu-theme($vts-light-theme);
@include mat.progress-spinner-theme($vts-light-theme);
@include mat.sidenav-theme($vts-light-theme);
@include mat.card-theme($vts-light-theme);
@include mat.snack-bar-theme($vts-light-theme);
@include mat.select-theme($vts-light-theme);
@include mat.form-field-theme($vts-light-theme);
@include mat.tabs-theme($vts-light-theme);
@include mat.datepicker-theme($vts-light-theme);
@include mat.table-theme($vts-light-theme);
@include mat.tooltip-theme($vts-light-theme);
@include mat.tree-theme($vts-light-theme);
@include mat.chips-theme($vts-light-theme);

body {
  background-color: var(--mat-sidenav-content-background-color);

  $foreground: map-get($vts-light-theme, "foreground");
  color: mat.m2-get-color-from-palette($foreground, "text");
}

.dark {
  $foreground: map-get($vts-dark-theme, "foreground");
  color: mat.m2-get-color-from-palette($foreground, "text");

  @include mat.button-color($vts-dark-theme);
  @include mat.icon-button-color($vts-dark-theme);
  @include mat.checkbox-color($vts-dark-theme);
  @include mat.core-color($vts-dark-theme);
  @include mat.divider-color($vts-dark-theme);
  @include mat.icon-color($vts-dark-theme);
  @include mat.list-color($vts-dark-theme);
  @include mat.menu-color($vts-dark-theme);
  @include mat.progress-spinner-color($vts-dark-theme);
  @include mat.sidenav-color($vts-dark-theme);
  @include mat.card-color($vts-dark-theme);
  @include mat.snack-bar-color($vts-dark-theme);
  @include mat.select-color($vts-dark-theme);
  @include mat.form-field-color($vts-dark-theme);
  @include mat.tabs-color($vts-dark-theme);
  @include mat.datepicker-color($vts-dark-theme);
  @include mat.table-color($vts-dark-theme);
  @include mat.tooltip-color($vts-dark-theme);
  @include mat.tree-color($vts-dark-theme);
  @include mat.chips-color($vts-dark-theme);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.mat-drawer {
  background-color: var(--mat-sidenav-content-background-color) !important;
}

.mat-mdc-table {
  background-color: var(--mat-sidenav-content-background-color) !important;
}

.mat-tree {
  background-color: var(--mat-sidenav-content-background-color) !important;
}

.mat-drawer-side {
  border: 0 !important;
}

// https://github.com/angular/components/issues/26203
.dark .mdc-data-table__row:hover {
  background-color: #ffffff0a;
}

.mdc-data-table__row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
